.container {
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  /* width: 100%; */
}

.tabs {
  display: flex;
  position: relative;
  border-radius: 99px;
  /* width: 100%; */
}
.tabs * {
  z-index: 2;
}

input[type="radio"] {
  display: none;
}

.mobileTab {
  padding: 20px 50px;
  border: none;
  border-radius: 50px;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  /* width: 250px; */
  padding: 0 35px;
  font-size: 1.25rem;
  font-weight: 600;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  transition: 0.15s ease-in;
}
.tab:hover {
  background: #d7d7d7;
  opacity: 0.5;
  transition: 0.15s ease-in;
}

input[type="radio"]:checked + label {
  color: rgba(0, 0, 0, 0.8);
}
input[type="radio"]:checked + label,
input[id="radio-1"]:checked ~ .glider-1 {
  transform: translateX(0);
}

input[id="radio-2"]:checked ~ .glider-2 {
  transform: translateX(109%);
}

input[id="radio-3"]:checked ~ .glider-3 {
  transform: translateX(235%);
}

input[id="radio-4"]:checked ~ .glider-4 {
  transform: translateX(335%);
}

.glider-1 {
  position: absolute;
  display: flex;
  height: 54px;
  width: 207px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}

.glider-2 {
  position: absolute;
  display: flex;
  height: 54px;
  width: 190.2px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}

.glider-3 {
  position: absolute;
  display: flex;
  height: 54px;
  width: 170px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}

.glider-4 {
  position: absolute;
  display: flex;
  height: 54px;
  width: 170.3px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}

@media screen and (max-width: 1920px) and (max-height: 1080px) {
  .glider-1 {
    width: 270px;
  }
  input[id="radio-2"]:checked ~ .glider-2 {
    transform: translateX(121%);
  }

  input[id="radio-3"]:checked ~ .glider-3 {
    transform: translateX(224.5%);
  }

  input[id="radio-4"]:checked ~ .glider-4 {
    transform: translateX(348%);
  }
  .glider-2 {
    width: 230px;
  }
  .glider-3 {
    width: 228px;
  }
  .glider-4 {
    width: 212px;
  }
}

@media screen and (max-width: 1920px) and (max-height: 929px) {
  .glider-1 {
    width: 270px;
  }
  input[id="radio-2"]:checked ~ .glider-2 {
    transform: translateX(115%);
  }

  input[id="radio-3"]:checked ~ .glider-3 {
    transform: translateX(239%);
  }

  input[id="radio-4"]:checked ~ .glider-4 {
    transform: translateX(348%);
  }
  .glider-2 {
    width: 230px;
  }
  .glider-3 {
    width: 210px;
  }
  .glider-4 {
    width: 206px;
  }
}

@media screen and (max-width: 1728px) and (max-height: 1117px) {
  .glider-1 {
    width: 260px;
  }
  input[id="radio-2"]:checked ~ .glider-2 {
    transform: translateX(115%);
  }

  input[id="radio-3"]:checked ~ .glider-3 {
    transform: translateX(227%);
  }

  input[id="radio-4"]:checked ~ .glider-4 {
    transform: translateX(339.5%);
  }
  .glider-2 {
    width: 230px;
  }
  .glider-3 {
    width: 217px;
  }
  .glider-4 {
    width: 209px;
  }
}

@media screen and (max-width: 1600px) {
  .glider-1 {
    width: 225px;
  }
  input[id="radio-2"]:checked ~ .glider-2 {
    transform: translateX(110%);
  }

  input[id="radio-3"]:checked ~ .glider-3 {
    transform: translateX(235.7%);
  }

  input[id="radio-4"]:checked ~ .glider-4 {
    transform: translateX(348%);
  }
  .glider-2 {
    width: 205px;
  }
  .glider-3 {
    width: 183px;
  }
  .glider-4 {
    width: 177px;
  }
}

@media screen and (max-width: 1536px) {
  .glider-1 {
    width: 223px;
  }

  input[id="radio-2"]:checked ~ .glider-2 {
    transform: translateX(110%);
  }

  input[id="radio-3"]:checked ~ .glider-3 {
    transform: translateX(235.7%);
  }

  input[id="radio-4"]:checked ~ .glider-4 {
    transform: translateX(348%);
  }
  .glider-2 {
    width: 201.3px;
  }
  .glider-3 {
    width: 180px;
  }
  .glider-4 {
    width: 174px;
  }
}

@media screen and (max-width: 1440px) and (max-height: 900px) {
  .glider-1 {
    width: 230px;
  }

  input[id="radio-2"]:checked ~ .glider-2 {
    transform: translateX(110%);
  }

  input[id="radio-3"]:checked ~ .glider-3 {
    transform: translateX(230%);
  }

  input[id="radio-4"]:checked ~ .glider-4 {
    transform: translateX(348%);
  }
  .glider-2 {
    width: 208.4px;
  }
  .glider-3 {
    width: 191px;
  }
  .glider-4 {
    width: 182px;
  }
}

@media screen and (max-width: 1440px) and (max-height: 632px) {
  .glider-1 {
    width: 215px;
  }

  input[id="radio-2"]:checked ~ .glider-2 {
    transform: translateX(110%);
  }

  input[id="radio-3"]:checked ~ .glider-3 {
    transform: translateX(235.7%);
  }

  input[id="radio-4"]:checked ~ .glider-4 {
    transform: translateX(348%);
  }
  .glider-2 {
    width: 208px;
  }
  .glider-3 {
    width: 187px;
  }
  .glider-4 {
    width: 182px;
  }
}

@media screen and (max-width: 1366px) and (max-height: 1024px) {
  .glider-1 {
    width: 230px !important;
  }

  input[id="radio-2"]:checked ~ .glider-2 {
    transform: translateX(110%);
  }

  input[id="radio-3"]:checked ~ .glider-3 {
    transform: translateX(219%);
  }

  input[id="radio-4"]:checked ~ .glider-4 {
    transform: translateX(334.5%);
  }
  .glider-2 {
    width: 210px !important;
  }
  .glider-3 {
    width: 200px !important;
  }
  .glider-4 {
    width: 190px !important;
  }
}

@media screen and (max-width: 1366px) and (max-height: 638px) {
  .glider-1 {
    width: 208.6px !important;
  }

  input[id="radio-2"]:checked ~ .glider-2 {
    transform: translateX(110%);
  }

  input[id="radio-3"]:checked ~ .glider-3 {
    transform: translateX(235.7%);
  }

  input[id="radio-4"]:checked ~ .glider-4 {
    transform: translateX(348%);
  }
  .glider-2 {
    width: 189.6px !important;
  }
  .glider-3 {
    width: 173px !important;
  }
  .glider-4 {
    width: 167px !important;
  }
}

@media screen and (max-width: 1366px) and (max-height: 768px) {
  .glider-1 {
    width: 217px !important;
  }

  input[id="radio-2"]:checked ~ .glider-2 {
    transform: translateX(112.5%);
  }

  input[id="radio-3"]:checked ~ .glider-3 {
    transform: translateX(231.5%);
  }

  input[id="radio-4"]:checked ~ .glider-4 {
    transform: translateX(348%);
  }
  .glider-2 {
    width: 195px !important;
  }
  .glider-3 {
    width: 180px !important;
  }
  .glider-4 {
    width: 172.5px !important;
  }
}

@media screen and (max-width: 1366px) and (max-height: 657px) {
  .glider-1 {
    width: 211px !important;
  }

  input[id="radio-2"]:checked ~ .glider-2 {
    transform: translateX(113%);
  }

  input[id="radio-3"]:checked ~ .glider-3 {
    transform: translateX(235.7%);
  }

  input[id="radio-4"]:checked ~ .glider-4 {
    transform: translateX(350%);
  }
  .glider-2 {
    width: 189.6px !important;
  }
  .glider-3 {
    width: 173px !important;
  }
  .glider-4 {
    width: 167px !important;
  }
}

@media screen and (max-width: 1280px) and (max-height: 670px) {
  .glider-1 {
    width: 204.2px;
  }

  input[id="radio-2"]:checked ~ .glider-2 {
    transform: translateX(111.5%);
  }

  input[id="radio-3"]:checked ~ .glider-3 {
    transform: translateX(234%);
  }

  input[id="radio-4"]:checked ~ .glider-4 {
    transform: translateX(348%);
  }
  .glider-2 {
    width: 186px;
  }
  .glider-3 {
    width: 170px;
  }
  .glider-4 {
    width: 164px;
  }
}

@media screen and (max-width: 1280px) and (max-height: 800px) {
  .glider-1 {
    width: 214px;
  }

  input[id="radio-2"]:checked ~ .glider-2 {
    transform: translateX(111.5%);
  }

  input[id="radio-3"]:checked ~ .glider-3 {
    transform: translateX(229%);
  }

  input[id="radio-4"]:checked ~ .glider-4 {
    transform: translateX(350.5%);
  }
  .glider-2 {
    width: 192px;
  }
  .glider-3 {
    width: 179px;
  }
  .glider-4 {
    width: 169px;
  }
}

@media screen and (max-width: 1024px) and (max-height: 1366px) {
  .glider-1 {
    width: 230px;
  }

  input[id="radio-2"]:checked ~ .glider-2 {
    transform: translateX(117%);
  }

  input[id="radio-3"]:checked ~ .glider-3 {
    transform: translateX(227%);
  }

  input[id="radio-4"]:checked ~ .glider-4 {
    transform: translateX(353%);
  }
  .glider-2 {
    width: 197x;
  }
  .glider-3 {
    width: 195px;
  }
  .glider-4 {
    width: 181px;
  }
}

@media only screen and (max-width: 1224px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
}

@media (max-width: 700px) {
  .tabs {
    transform: scale(0.6);
  }
}
