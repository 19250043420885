* {
  /* transition: all 0.3s ease-in-out; */
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background: #f3efea;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

.mySwiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.mySwiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: calc((100% - 30px) / 2) !important;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide {
  width: 80%;
}

.MuiTabs-flexContainer, .MuiTabs-root{
  width: 100%;
  justify-content: center;
}



.swiper-slide:nth-child(2n) {
  width: 80%;
}

.swiper-slide:nth-child(3n) {
  width: 80%;
}

.swiper-slide:nth-child(4n) {
  width: 80%;
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}



#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {opacity: 0.7;}

/* The Modal (background) */
.modal { 
  position: fixed; /* Stay in place */
  z-index: 999999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content, #caption {  
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}

@media screen and(max-width:820px){
  .MuiTabs-flexContainer, .MuiTabs-root{
    width: 100%;
    justify-content: space-between !important;
  }
}

@media screen and (max-width: 1440px) and (max-height: 632px) {
  .glider-1 {
    width: 215px !important;
  }

  input[id="radio-2"]:checked ~ .glider-2 {
    transform: translateX(110%);
  }

  input[id="radio-3"]:checked ~ .glider-3 {
    transform: translateX(235.7%);
  }

  input[id="radio-4"]:checked ~ .glider-4 {
    transform: translateX(348%);
  }
  .glider-2 {
    width: 196px;
  }
  .glider-3 {
    width: 176px;
  }
  .glider-4 {
    width: 170px;
  }
}

/* @media screen and (max-width:1024px){
    body{
      zoom: 80%;
    }
} */

@media screen and (min-width: 320px) and (max-width: 820px) {
  .aboutText {
    max-width: calc(100% - 94px) !important;
  }
}

/* @media screen and (max-width:880px){
  body{
    zoom: 60%;
  }
} */

